import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";

const swiperEvents = new Swiper(".swiper-dogodki", {
  modules: [Autoplay, Navigation],
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  navigation: {
    nextEl: ".swiper-dogodki-next",
    prevEl: ".swiper-dogodki-prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  },
});
